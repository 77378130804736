/* eslint-disable no-console */
import { createContext, PropsWithChildren, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import uniqBy from 'lodash/uniqBy';
import { Socket } from 'socket.io-client';

import { useLazyGetAppointmentsQuery } from 'services/appointments/appointments';

import { selectRefreshToken, selectUser } from 'store';
import { setUser } from 'store/user/userSlice';

import { notifyError } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import { errorCallBack, socketReconnect, SocketResProps } from 'socket';
import { PathName } from 'utils/enums';

import {
  AppointmentUpdateProps,
  ChannelDetailsProps,
  ChannelProps,
  DateMessagesProps,
  GetOldMessageParams,
  MarkSeenParams,
  MessageProps,
  MessagesContextParams,
  NewChannelCreatedProps,
  NewMessageToServerParams,
  PatientDoctorUpdateProps,
  TotalMessageCountUpdateProps,
  UnreadMessageUpdatedProps
} from './messagesContext.types';

export const MessagesContext = createContext<MessagesContextParams>({
  channelDetails: null,
  channels: [],
  clearChannels: () => {},
  closeChannel: () => {},
  dateMessages: null,
  generalHealth: '',
  getChannels: () => {},
  getOldMessage: () => {},
  isLoading: true,
  joinRoom: () => {},
  markSeen: () => {},
  sendMessage: () => {},
  totalMessageCountUpdate: null
});

export const MessagesProvider: React.FC<PropsWithChildren<{ socket: Socket }>> = ({
  children,
  socket
}) => {
  const [getAppointments] = useLazyGetAppointmentsQuery();
  const refreshToken = useAppSelector(selectRefreshToken);
  const { doctorId } = useAppSelector(selectUser);
  const [listOfMessages, setListOfMessages] = useState<MessageProps[] | null>(null);
  const [dateMessages, setDateMessages] = useState<DateMessagesProps[] | null>(null);
  const [unreadMessageUpdated, setUnreadMessageUpdated] =
    useState<UnreadMessageUpdatedProps | null>(null);
  const [generalHealth, setGeneralHealth] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(!!doctorId);
  const dispatch = useAppDispatch();

  const [channels, setChannels] = useState<ChannelProps[]>([]);
  const [channelDetails, setChannelDetails] = useState<ChannelDetailsProps | null>(null);
  const [historyCatchUp, setHistoryCatchUp] = useState<MessageProps[] | null>(null);
  const [oldMessages, setOldMessages] = useState<MessageProps[]>([]);
  const [messageToClients, setMessageToClients] = useState<MessageProps | null>(null);
  const [markedSeenMessage, setMarkedSeenMessage] = useState<MessageProps | null>(null);
  const [totalMessageCountUpdate, setTotalMessageCountUpdate] =
    useState<TotalMessageCountUpdateProps | null>(null);
  const [newChannelCreated, setNewChannelCreated] = useState<NewChannelCreatedProps | null>(null);
  const [skip, setSkip] = useState(1);

  const closeChannel = () => {
    setOldMessages([]);
    setDateMessages(null);
    setChannelDetails(null);
  };

  const clearChannels = () => setChannels([]);

  const joinRoom = (channelId: string) => {
    socket.emit('joinRoom', channelId, (error: SocketResProps) => errorCallBack('joinRoom', error));
  };

  const markSeen = (params: MarkSeenParams) => {
    socket.emit('markSeen', params, (error: SocketResProps) => errorCallBack('markSeen', error));
  };

  const sendMessage = (params: NewMessageToServerParams) => {
    socket.emit(
      'newMessageToServer',
      params,
      window.location.pathname === PathName.ZoomCall,
      (error: SocketResProps) => errorCallBack('newMessageToServer', error)
    );
  };

  const getOldMessage = (params: GetOldMessageParams, cb: () => void) => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    socket.emit('getNextMessage', params, (error: SocketResProps) => {
      setIsLoading(false);
      errorCallBack('getNextMessage', error);
    });
    cb();
  };

  const getChannels = () => {
    socket.emit(
      'listChannels',
      {
        limit: 10,
        skip
      },
      (error: SocketResProps) => errorCallBack('listChannels', error)
    );
    setSkip(skip + 1);
  };

  const handleSetOldMessages = (data: MessageProps[]) => {
    setIsLoading(false);
    setOldMessages(data);
  };

  const handleUpdatedOldMessages = () => {
    if (oldMessages.length) {
      setHistoryCatchUp([...oldMessages, ...(listOfMessages || [])]);
    }
  };

  const updateChannel = (data: {
    channelId: string;
    latestMessage?: string;
    latestMessageDate?: Date;
    unreadMessageCount?: number;
  }) => {
    setChannels(
      channels.map((channel) => {
        if (channel.channelId === data.channelId) {
          return {
            ...channel,
            ...data
          };
        }

        return channel;
      })
    );
  };

  const handleUpdatedHistoryCatchUp = () => {
    if (!channelDetails || !historyCatchUp) {
      return;
    }
    historyCatchUp.map((el) => {
      el.isBlur = true;
      return el;
    });
    const filteredData = historyCatchUp.filter(
      (message) => message.channelId === channelDetails._id
    );
    if (!filteredData.length && historyCatchUp.length) {
      return;
    }
    setListOfMessages(filteredData);
  };

  const handleUpdatedMessageToClients = () => {
    if (messageToClients) {
      setListOfMessages([...(listOfMessages || []), messageToClients]);
      updateChannel({
        channelId: messageToClients.channelId,
        latestMessage: messageToClients.message,
        latestMessageDate: messageToClients.messageStatus.sent
      });
    }
  };

  const handleUpdatedChannels = () => {
    if (channels.length) {
      setIsLoading(false);
      setGeneralHealth(
        channels.find(
          ({ channelTitle }) => channelTitle?.toLowerCase() === 'General health'.toLowerCase()
        )?.channelId || ''
      );
    }
  };

  const handleNewChannelCreated = () => {
    if (newChannelCreated && channels) {
      setChannels([
        ...channels,
        {
          _id: newChannelCreated.patientDetail.id,
          channelDescription: newChannelCreated.channelDescription,
          channelId: newChannelCreated._id,
          channelTitle: newChannelCreated.channelTitle,
          latestMessageDate: newChannelCreated.latestMessageDate,
          patientId: newChannelCreated.patientDetail.id,
          unreadMessageCount: 0
        }
      ]);
    }
  };

  const handleUpdatedMarkedSeenMessage = () => {
    if (markedSeenMessage && listOfMessages) {
      const index = listOfMessages.findIndex((item) => item._id === markedSeenMessage._id);
      if (listOfMessages[index]) {
        listOfMessages[index] = {
          ...listOfMessages[index],
          receiverDetails: markedSeenMessage.receiverDetails
        };
        setListOfMessages([...listOfMessages]);
      }
    }
  };

  const handleUpdatedUnreadMessageUpdated = () => {
    if (unreadMessageUpdated) {
      updateChannel(unreadMessageUpdated);
    }
  };

  const handleUpdatedListOfMessages = () => {
    if (!listOfMessages) {
      return;
    }
    const formattedData: DateMessagesProps[] = [];

    listOfMessages.forEach((message) => {
      const sentDate = new Date(message.messageStatus.sent).toDateString();
      const current = formattedData.find(({ date }) => date === sentDate);

      if (current) {
        formattedData[formattedData.indexOf(current)].messages.push(message);
      } else {
        formattedData.push({
          date: sentDate,
          messages: [message]
        });
      }
    });

    const sorted = formattedData
      .map(({ date, messages }) => ({
        date,
        messages: messages.sort((a, b) => dayjs(a.messageStatus.sent).diff(b.messageStatus.sent))
      }))
      .sort((a, b) => dayjs(a.date).diff(b.date));

    setDateMessages(sorted);
    setIsLoading(false);
  };

  const handleSetChannels = (items: ChannelProps[]) => {
    setChannels((prev) =>
      uniqBy([...prev, ...items.filter((el) => el.channelId !== undefined)], 'channelId')
    );
  };

  const handleSetAppointmentUpdate = (appointment: AppointmentUpdateProps) => {
    getAppointments({ limit: 50 });
    if (appointment.appointmentStatus === 'cancelled') {
      notifyError('This appointment has been cancelled.', true);
    }
  };

  const handlePatientDoctorUpdate = (params: PatientDoctorUpdateProps) => {
    dispatch(setUser({ doctorId: params.doctorId }));
  };

  useEffect(handleUpdatedOldMessages, [oldMessages]);

  useEffect(handleUpdatedMessageToClients, [messageToClients]);

  useEffect(handleUpdatedChannels, [channels]);

  useEffect(handleNewChannelCreated, [newChannelCreated]);

  useEffect(handleUpdatedMarkedSeenMessage, [markedSeenMessage]);

  useEffect(handleUpdatedUnreadMessageUpdated, [unreadMessageUpdated]);

  useEffect(handleUpdatedHistoryCatchUp, [historyCatchUp, channelDetails]);

  useEffect(handleUpdatedListOfMessages, [listOfMessages]);

  useEffect(() => {
    socket.on('channels', handleSetChannels);
    socket.on('channelsList', handleSetChannels);
    socket.on('newChannelCreated', setNewChannelCreated);
    socket.on('markedSeen', setMarkedSeenMessage);
    socket.on('oldMessages', handleSetOldMessages);
    socket.on('historyCatchUp', setHistoryCatchUp);
    socket.on('channelDetails', setChannelDetails);
    socket.on('messageToClients', setMessageToClients);
    socket.on('unreadMessageUpdated', setUnreadMessageUpdated);
    socket.on('totalMessageCountUpdate', setTotalMessageCountUpdate);
    socket.on('appointmentUpdate', handleSetAppointmentUpdate);
    socket.on('patientDoctorUpdate', handlePatientDoctorUpdate);
    socket.on('connect', () => console.info('messages connect'));
    socket.on('connect_error', (err) => {
      if (err.message === 'AUTH_EXPIRED') {
        socketReconnect(socket, refreshToken);
      }
    });
    socket.on('disconnect', () => console.info('messages disconnect'));
    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('channels');
      socket.off('channelsList');
      socket.off('newChannelCreated');
      socket.off('markedSeen');
      socket.off('oldMessages');
      socket.off('historyCatchUp');
      socket.off('channelDetails');
      socket.off('messageToClients');
      socket.off('unreadMessageUpdated');
      socket.off('totalMessageCountUpdate');
      socket.off('appointmentUpdate');
      socket.off('patientDoctorUpdate');
      socket.off('connect');
      socket.off('connect_error');
    };
  }, []);

  return (
    <MessagesContext.Provider
      value={{
        channelDetails,
        channels,
        clearChannels,
        closeChannel,
        dateMessages,
        generalHealth,
        getChannels,
        getOldMessage,
        isLoading,
        joinRoom,
        markSeen,
        sendMessage,
        totalMessageCountUpdate
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};
