import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';

import { DateFormat } from 'utils/enums';

import { ShopOrderItemShortInfo } from 'models/shop';

interface OrderItemProps {
  order: ShopOrderItemShortInfo;
}

const OrderItem: React.FC<OrderItemProps> = ({
  order: { createdAt, _id: id, items, total, trackingNumber }
}) => {
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <div className="flex flex-col gap-1">
          <span className="font-bold">LFMD-{id}</span>
          <span>Tracking: {trackingNumber}</span>
          <span className="text-gray">
            {dayjs(createdAt).format(DateFormat.MMMM_DD_YYYY)} • {items.length} Items
          </span>
        </div>
        <span className="text-mBase font-bold">${total}</span>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-semibold">Receipt</span>
        <Common.Icon className="text-gray" name="arrow-right" />
      </div>
    </div>
  );
};

export default OrderItem;
