import classNames from 'classnames';

import PaymentIcon from 'shared/PaymentIcon';

import { ShopOrderDetails as ShopOrderDetailsType } from 'models/shop';

import PaypalSvg from 'assets/icons/paypal.svg?react';

interface PaymentMethodDetailsProps {
  data?: ShopOrderDetailsType['invoice']['payment']['paymentMethod'];
}

const PaymentMethodDetails: React.FC<PaymentMethodDetailsProps> = ({ data }) => {
  const isCardType = data?.type === 'card';

  return !!data ? (
    <div className="flex items-center gap-4 rounded-2xl border border-gray-200 p-4">
      <div
        className={classNames(
          'flex h-11 w-[60px] flex-none items-center rounded-lg px-4 py-2',
          isCardType ? 'bg-primary-100' : 'bg-[#FEC53B]'
        )}
      >
        {isCardType ? (
          <PaymentIcon cardType={data.type} />
        ) : (
          <PaypalSvg className="h-auto max-w-full" />
        )}
      </div>
      <div className="flex grow flex-wrap gap-2 max-md:flex-col md:items-center md:justify-between">
        <div className="flex flex-col gap-1">
          {isCardType ? (
            <>
              <span>
                xxxx-xxxx-xxxx-
                <span className="font-bold">
                  {data.maskedCardNumber.substring(data.maskedCardNumber.length - 4)}
                </span>
              </span>
              <span className="flex gap-1 text-gray">Expires&nbsp; {data.expiresAt}</span>
            </>
          ) : (
            <>
              <span>Paypal</span>
              <span className="max-w-[150px] truncate text-gray">{data.email}</span>
            </>
          )}
        </div>
      </div>
    </div>
  ) : (
    <span className="text-gray">No payment details...</span>
  );
};

export default PaymentMethodDetails;
