import { useEffect } from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import { nanoid } from 'nanoid';

import {
  useGetCreditBalanceQuery,
  useGetCreditCardInformationQuery,
  useLazyPreviewChangeSubscriptionQuery,
  useUpdateDefaultPaymentMethodMutation,
  useUpdatePaymentProfileMutation
} from 'services/myAccount/myAccount';

import { selectLookup, selectOrchestrate, selectUser } from 'store';
import { ValidCouponCodes } from 'store/signup/signup.types';

// import { getScalesPriceByPPLength } from 'containers/SignUp/signup.settings';
import AdvantageItem from 'features/AdvantageItem';
import PaymentMethods from 'features/PaymentMethods';
import { notifySuccess } from 'shared/Toast/Toast';
import UpdatePlanCard from 'widgets/payment/UpdatePlanCard';

import { DUMMY_PRICE_OF_AT_HOME_LABS } from 'constants/defaults';
import { useAppSelector } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { WM_DUMMY_ADVANTAGES } from 'utils/constants';
import { FlowTypes, PlanCodes } from 'utils/enums';
import { handleRequestCatch, parseTime } from 'utils/helpers';

import Heading from '../parts/Heading';

const MigrationCheckout: React.FC<{
  onContinue: () => void;
  selectedFlow?: FlowTypes;
}> = ({ onContinue, selectedFlow }) => {
  const { send, isLoading: isSendingOrchestrate } = useSubmitOrchestrateForm();
  const { data: balance, isFetching: isGettingCreditBalance } = useGetCreditBalanceQuery();
  const { data: creditCardData, isFetching, refetch } = useGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();
  useUpdatePaymentProfileMutation();

  const {
    payment: { product_price_point_handle, coupon_codes, product_handle },
    appointment: { appointmentTime },
    mif_qa: mifQA
  } = useAppSelector(selectOrchestrate);
  const { activePlanCode, activePricePoint, activePlanName } = useAppSelector(selectUser);

  const [
    getPreviewChangeSubscription,
    { data: previewChangeSubscription, isLoading: previewChangeSubscriptionLoading }
  ] = useLazyPreviewChangeSubscriptionQuery();
  const { membershipPlans } = useAppSelector(selectLookup);

  const WMPLan = membershipPlans.find((p) => p.planCode === PlanCodes.WeightManagementMembership);
  const WMPricePoints = WMPLan?.pricePoints;
  const selectedPP = WMPricePoints?.find(
    (pp) => pp.planPricePointId === product_price_point_handle
  );

  const existingPlan = membershipPlans.find((plan) => plan.planCode === activePlanCode);
  const existingPricePoint = existingPlan?.pricePoints.find(
    (pp) => pp.planPricePointId === activePricePoint
  );

  const doesIncludesLabsAtHome = mifQA.find((q) => q.question === 'labs')?.answer === 'ash';

  const getAdditionalPurchaseInfo = () => {
    // const priceWithDiscount = getScalesPriceByPPLength(selectedPP);
    // if (includesScales) {
    //   return {
    //     header: 'Withings Body Pro 2 Scale',
    //     subheader: 'Shipping included with Rx',
    //     price: priceWithDiscount ?? '',
    //     color: 'text-secondary-600'
    //   };
    // }
    if (doesIncludesLabsAtHome) {
      return {
        header: 'At-home lab kit',
        price: DUMMY_PRICE_OF_AT_HOME_LABS,
        color: 'text-secondary'
      };
    }
    return undefined;
  };

  const handleFavoritePaymentMethod = (id: string, cb?: () => void) => {
    if (id) {
      updateDefaultPaymentMethod({ id })
        .unwrap()
        .then((r) => {
          refetch()
            .unwrap()
            .then(() => {
              notifySuccess(r.message ?? 'Updated default payment method');
              cb?.();
            });
        })
        .catch(handleRequestCatch);
    }
  };

  const handleUpdateMyAccount = () => {
    send(
      'payment',
      { coupon_codes, product_price_point_handle, product_handle },
      onContinue,
      'USER'
    );
  };

  const isDisabled = isUpdatingDefaultPaymentMethod || isFetching;
  const creditBalance = balance?.data.creditBalance;

  useEffect(() => {
    !!WMPLan &&
      getPreviewChangeSubscription({
        planId: WMPLan._id,
        planPricePointId: product_price_point_handle,
        couponCode: coupon_codes[0] as ValidCouponCodes
      });
  }, []);

  return (
    <div className="flex flex-col gap-6">
      <Heading category="Sign up" title="Confirm and pay" />
      <div className="flex gap-6 max-md:flex-col md:gap-11">
        <div className="flex flex-col gap-8 md:grid md:grid-cols-2">
          {selectedPP && (
            <div className="max-md:self-stretch">
              {!!previewChangeSubscription?.data && (
                <UpdatePlanCard
                  additionalPurchaseInfo={getAdditionalPurchaseInfo()}
                  creditBalance={creditBalance}
                  oldPlan={
                    existingPricePoint && {
                      planName: existingPricePoint.planName ?? activePlanName ?? '',
                      pricePoint: existingPricePoint
                    }
                  }
                  plan={{
                    planName: selectedPP.planName ?? WMPLan?.planName ?? '',
                    pricePoint: selectedPP
                  }}
                  previewChangeSubscription={previewChangeSubscription.data}
                />
              )}
            </div>
          )}
          {selectedFlow === FlowTypes.TripleTherapy ? (
            <div className="rounded-2xl shadow">
              <div className="flex gap-4 rounded-t-2xl bg-primary-100 p-6 text-mXl text-primary-700">
                <div className="flex size-12 flex-none rounded-full bg-primary-200">
                  <Common.Icon className="m-auto size-8 text-primary" name="doctor" />
                </div>
                <div className="flex flex-col gap-1">
                  <h2 className="text-mLg font-bold md:text-xl">Appointment</h2>
                  <span className="text-mSm md:text-base">
                    Your provider • {parseTime(appointmentTime?.startTime ?? '')}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-2 p-4 text-mSm">
                {[
                  <span>
                    <span className="font-bold">No needles, no complicated routines</span> — just
                    convenient oral medication that fits seamlessly into your daily life for
                    effective weight loss support.
                  </span>,
                  <span>
                    <span className="mr-1 font-bold">Affordable</span>
                    medication that is significantly less expensive than GLP-1 medications, making
                    effective weight loss more accessible.
                  </span>,
                  <span>
                    <span className="font-bold">Ongoing clinical support</span> from licensed
                    medical professionals through messaging and video appointments.
                  </span>
                ].map((el) => (
                  <div className="flex items-center gap-2" key={nanoid()}>
                    <Common.Icon className="flex-none text-secondary" name="check" />
                    {el}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div>
              <div className="flex flex-col gap-2">
                <h2 className="font-bold text-primary-700">
                  LifeMD GLP-1 Weight Management ${selectedPP?.paymentInterval.qty}-Month Program:
                </h2>
                {WM_DUMMY_ADVANTAGES.map((el) => (
                  <AdvantageItem advantage={el} key={el} showCustomIcon />
                ))}
              </div>
            </div>
          )}
          {previewChangeSubscription?.data.migrationHasPayment && (
            <div className="col-span-2 md:mx-auto md:w-2/3">
              <PaymentMethods
                creditBalance={creditBalance}
                isLoading={isGettingCreditBalance}
                paymentMethods={creditCardData?.data ?? []}
                onAddMethod={refetch}
                onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
              />
            </div>
          )}
        </div>
      </div>
      <Common.Button
        className="mt-auto md:mx-auto md:mt-4"
        color="blue"
        dataTestId="confirm_btn"
        disabled={isDisabled}
        isLoading={isSendingOrchestrate || previewChangeSubscriptionLoading}
        fullWidthOnMobile
        onClick={handleUpdateMyAccount}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default MigrationCheckout;
