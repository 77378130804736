import React from 'react';
import { Common } from '@thecvlb/design-system/lib/src';
import dayjs from 'dayjs';

import { BaseModalProps } from 'modals/modal.types';
import Loader from 'shared/Loader';

import { STORE_ORDER_TRACKING_URL } from 'constants/externalLinks';
import { DateFormat } from 'utils/enums';

import { ShopOrderDetails as ShopOrderDetailsType } from 'models/shop';
interface ShopOrderDetailsProps extends BaseModalProps {
  data?: ShopOrderDetailsType;
  loading?: boolean;
}

const ShopOrderDetails: React.FC<ShopOrderDetailsProps> = ({ loading, data, isOpen, onClose }) => {
  return (
    <Common.Modal close={onClose} isOpen={isOpen} padding={false} size="base">
      {loading ? (
        <Loader isVisible />
      ) : !!data ? (
        <div className="flex flex-col gap-6 p-4">
          <div className="flex flex-col gap-2">
            <span className="text-gray">Invoice #LFMD-{data._id}</span>
            <span className="text-mLg font-bold">{data.items[0].componentId}</span>
            <span>{dayjs(data.createdAt).format(DateFormat.MMMM_DD_YYYY)}</span>
          </div>
          <div className="flex flex-col gap-4 rounded-2xl border border-gray-200 bg-gray-50 p-4">
            {data.items.map((item) => (
              <div className="flex items-center justify-between">
                <span>{item.productId}</span>
                <span>{item.pricePointId}</span>
              </div>
            ))}
            {/* NO DISCOUNTS FOR V1 */}
            {/* <div className="flex items-center justify-between text-orange">
              <span>50% off</span>
              <span>-$124.50</span>
            </div> */}
            <div className="flex items-center justify-between font-bold">
              <span>Total due</span>
              <span>${data.total}</span>
            </div>
            <div className="h-px w-full bg-gray-200"></div>
            <div>
              <div className="flex items-center justify-between font-bold">
                <span>Amount paid</span>
                <span>${data.total}</span>
              </div>
              <span>
                Payment from {data.invoice.payment.paymentMethod.cardBrand} -{' '}
                {data.invoice.payment.paymentMethod.maskedCardNumber}
              </span>
            </div>
          </div>
          <div>
            <span className="mb-1 block font-bold">Shipment: In Transit</span>
            <span>{data.trackingNumber}</span>
          </div>
          <div className="flex gap-3 max-md:flex-col max-md:py-6 md:justify-center">
            <Common.Button
              color="blue"
              fullWidthOnMobile
              onClick={() => window.open(STORE_ORDER_TRACKING_URL + data.trackingNumber, '_blank')}
            >
              Track Shipment
            </Common.Button>
            <Common.Button color="white-alt" preIcon="download" fullWidthOnMobile>
              Download
            </Common.Button>
          </div>
        </div>
      ) : (
        <span className="m-auto block text-center text-gray">
          Error while getting information, please try again later
        </span>
      )}
    </Common.Modal>
  );
};

export default ShopOrderDetails;
