import { Link } from 'react-router-dom';

import { PathName } from 'utils/enums';

import { ProductsList } from 'models/shop';

interface ProductCardProps {
  info: ProductsList[number];
}

const ProductCard: React.FC<ProductCardProps> = ({
  info: {
    type,
    _id: id,
    name,
    featuredAsset: { url },
    price: { unitPrice: price },
    originPrice: { unitPrice: oldPrice }
  }
}) => {
  const isPriceWithDiscount = Number(oldPrice) > Number(price);

  return (
    <Link className="group flex flex-col gap-1" to={PathName.ShopProducts + `/${id}`}>
      <div className="rounded-xl bg-gray-100 p-6 transition-all group-hover:shadow">
        <img
          alt={name}
          className="mx-auto h-[175px] max-w-full object-contain transition-all group-hover:scale-[102%]"
          src={url}
        />
      </div>
      <div>
        <h4 className="text-mBase font-semibold text-black">{name}</h4>
        <span className="text-gray">{type}</span>
        <p>
          {isPriceWithDiscount ? (
            <span className="text-green">
              <span className="mr-1 text-gray line-through">${oldPrice}</span>${price}
            </span>
          ) : (
            <span className="text-gray-700">${price}</span>
          )}
        </p>
      </div>
    </Link>
  );
};

export default ProductCard;
