import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import RadioGroup from 'shared/RadioGroup';
import Heading from 'widgets/wmOnboarding/parts/Heading';

import { DUMMY_PRICE_OF_AT_HOME_LABS } from 'constants/defaults';
import { useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { FlowTypes } from 'utils/enums';
import { buildSearch } from 'utils/helpers';

import LabKit from 'assets/images/signUp/lab-kit.webp';
import LabTesting from 'assets/images/signUp/lab-testing.svg';

type Props = {
  onContinue: () => void;
  selectedFlow?: FlowTypes | 'authorized';
};

const OPTIONS = [
  {
    label: (
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex flex-col gap-1">
          <span className="text-mSm font-bold text-primary-400">
            Only ${DUMMY_PRICE_OF_AT_HOME_LABS}
          </span>
          <span className="font-semibold">At-home lab kit</span>
          <span className="text-mSm text-gray">Shipping included</span>
        </div>
        <img alt="labs-at-home" className="size-16" src={LabKit} />
      </div>
    ),
    value: 'ash'
  },
  {
    label: (
      <div className="flex justify-between gap-2">
        <div className="flex flex-col gap-1">
          <span className="text-mSm text-gray">No cost</span>
          <span className="font-semibold">I’ll go to a lab near me</span>
        </div>
      </div>
    ),
    value: 'elation'
  }
];

const LabWorkAlt: React.FC<Props> = ({ onContinue, selectedFlow }) => {
  const { send, isLoading } = useSubmitOrchestrateForm();
  const navigate = useNavigate();
  const subStep = useQuery().get('subStep');
  const [activeItem, setActiveItem] = useState<string>();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAgreementAccepted, setIsAgreementAccepted] = useState(false);
  const [isSecondaryAgreementAccepted, setIsSecondaryAgreementAccepted] = useState(false);

  const handleSelect = () => {
    if (!subStep) {
      navigate({
        search: buildSearch(
          selectedFlow === 'authorized' ? 's=lab-work' : '',
          `subStep=labs-at-home`
        )
      });
    } else {
      handleSubmit();
    }
  };

  const handleSubmit = () =>
    !!activeItem && send('mif_qa', [{ answer: activeItem, question: 'labs' }], onContinue);

  const getDisabledStatus = () => {
    if (!subStep) {
      return !isAgreementAccepted;
    }
    return !activeItem || (activeItem === 'ash' && !isSecondaryAgreementAccepted);
  };

  return (
    <div className="flex flex-col gap-6 max-md:h-full">
      {!subStep && (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-4">
            <div className="self-center rounded-full bg-secondary-100 p-4">
              <img alt="lab-work" className="self-center" src={LabTesting} />
            </div>
            <div className="flex flex-col gap-2">
              <h2 className="wm-signup-title" data-testid="header">
                Heads up: lab work will be required
              </h2>
              <span className="text-primary-700">
                Lab work to assess kidney and liver function will be required to proceed with your
                second round of medication. We strongly recommend you complete this lab work prior
                to starting your first dose.
              </span>
              <span className="text-mXs text-gray">
                LifeMD will provide a lab order for you to complete a comprehensive metabolic panel
                (CMP), which is a routine blood test.
              </span>
            </div>
          </div>
          <Common.Checkbox
            checked={isAgreementAccepted}
            className="!items-start"
            color="blue"
            onChange={() => setIsAgreementAccepted((p) => !p)}
          >
            <span className="text-base">
              I understand that there are risks associated with starting the Triple Therapy
              medication protocol without completing the recommended lab work. I understand that
              completing lab work is required before receiving my first medication refill, and may
              be required at any time prior to attaining subsequent medication refills.
            </span>
          </Common.Checkbox>
        </div>
      )}
      {subStep === 'labs-at-home' && (
        <div className="flex h-full flex-col justify-between gap-6">
          <Heading
            category="Plan"
            subtitle="You may complete lab work in the comfort of your home or opt to go to a local Quest lab."
            title="Where would you like to do your lab work?"
          />
          <RadioGroup
            isLoading={isLoading}
            items={OPTIONS}
            selectedItem={activeItem}
            onSelect={setActiveItem}
          />
          {activeItem === 'ash' && (
            <Common.Checkbox
              checked={isSecondaryAgreementAccepted}
              className="!items-start"
              color="blue"
              onChange={() => setIsSecondaryAgreementAccepted((p) => !p)}
            >
              <p className="flex flex-col gap-6 px-0.5 text-base">
                <span>
                  I authorize specimen collection with a dried blood spot collection. I further
                  understand, agree, certify, and authorize the following:{' '}
                  {!isExpanded && (
                    <button
                      className="underline"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsExpanded(true);
                      }}
                    >
                      Expand
                    </button>
                  )}
                </span>
                {isExpanded && (
                  <>
                    <span>
                      This test involves a dried blood spot card which will require a capillary
                      sample extracted via a lancet. It may be uncomfortable, painful, or
                      potentially cause mild abrasion or bleeding. No long-lasting side effects from
                      testing are expected.
                    </span>
                    <span>
                      LifeMD, Inc. has contracted with Ash Wellness, Inc. and their partner labs for
                      laboratory analysis and report of my specimen.
                    </span>
                    <span>
                      I understand that I am not entering into a doctor-patient relationship with
                      Ash Wellness, Inc or LifeMD, Inc. and that any questions or required follow up
                      shall be my responsibility to arrange with my own provider.
                    </span>
                    <span>
                      At-home lab kits are non-returnable and non-refundable. Patient may be
                      responsible for the cost of a replacement kit if an error occurs in sample
                      collection or packaging of return shipment, and/or if an incorrect shipping
                      address is provided.
                    </span>
                  </>
                )}
              </p>
            </Common.Checkbox>
          )}
        </div>
      )}
      <Common.Button
        className="mt-auto md:self-center"
        color="blue"
        disabled={getDisabledStatus()}
        isLoading={isLoading}
        fullWidthOnMobile
        onClick={handleSelect}
      >
        Continue
      </Common.Button>
    </div>
  );
};

export default LabWorkAlt;
