import { NavLink, To, useMatch, useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { selectShop } from 'store';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { useAppSelector } from 'hooks';
import { useRouteMatch } from 'hooks/useRouteMatch';
import { PageTitle, PathName } from 'utils/enums';

const BreadCrumbs = () => {
  const isShopHomePage = !!useRouteMatch(PathName.Shop)?.isExact;
  const isShopCartPage = !!useRouteMatch(PathName.ShopCart)?.isExact;
  const isShopCheckoutPage = !!useRouteMatch(PathName.ShopCheckout)?.isExact;
  const isShopAddressPage = !!useRouteMatch(PathName.ShopAddress)?.isExact;
  const isOrderHistoryPage = !!useRouteMatch(PathName.ShopOrders)?.isExact;
  const isSingleOrderPage = !!useRouteMatch(PathName.ShopOrders);
  const checkMatch = useMatch(PathName.ShopProducts + '/:id');

  const isProductDetailsPage = checkMatch?.pattern.path === PathName.ShopProducts + '/:id';
  const { activeProductName } = useAppSelector(selectShop);

  const navigate = useNavigate();

  const getHeaderParams = (): { label: string; url?: To } => {
    switch (true) {
      case isShopHomePage:
        return {
          label: PageTitle.Shop,
          url: PathName.WeightManagement
        };
      case isProductDetailsPage:
        return {
          label: activeProductName,
          url: PathName.Shop
        };
      case isShopCartPage:
        return {
          label: 'Cart',
          url: -1 as To
        };
      case isShopCheckoutPage:
        return {
          label: 'Confirm and pay',
          url: -1 as To
        };
      case isShopAddressPage:
        return {
          label: 'Shipping address',
          url: -1 as To
        };
      case isOrderHistoryPage:
        return {
          label: 'Order History',
          url: -1 as To
        };
      case isSingleOrderPage:
        return {
          label: '',
          url: ''
        };
      default:
        return {
          label: '',
          url: ''
        };
    }
  };

  const { label, url } = getHeaderParams();
  const showBreadCrumbs = !isShopCheckoutPage && !isShopAddressPage;
  return !!showBreadCrumbs ? (
    <div
      className={classNames('flex w-full justify-between', {
        'mb-8': !!url
      })}
    >
      {!!url && (
        <NavLink className="flex items-center gap-3 text-mLg font-bold" to={url}>
          <Common.Icon className="size-5" name="arrow-left" />
          <span>{label}</span>
        </NavLink>
      )}
      {isShopHomePage && (
        <div>
          <div className="flex items-center gap-2">
            <button className="flex-none p-2" onClick={() => navigate(PathName.ShopOrders)}>
              <Common.Icon className="size-5 flex-none" name="clock" />
            </button>
            <button
              className="flex-none rounded-full border border-gray-200 p-2"
              onClick={() => navigate(PathName.ShopCart)}
            >
              <Common.Icon className="size-5 flex-none" name="checkout" />
            </button>
          </div>
        </div>
      )}
      {isOrderHistoryPage && (
        <Common.Button onClick={() => window.open(LINK_TO_SUPPORT)}>Contact support</Common.Button>
      )}
    </div>
  ) : null;
};

export default BreadCrumbs;
