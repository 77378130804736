import { Outlet } from 'react-router-dom';
import { useTitle } from 'react-use';

import BreadCrumbs from 'features/store/BreadCrumbs';

const Shop = () => {
  useTitle('Store');

  return (
    <div className="h-full md:rounded-2xl md:bg-white md:p-8 md:shadow">
      <div className="max-md:hidden">
        <BreadCrumbs />
      </div>
      <Outlet />
    </div>
  );
};

export default Shop;
