import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useLazyGetLatestHealthMetricsQuery } from 'services/healthMetrics/healthMetrics';
import { useGetMembershipPlansQuery } from 'services/lookup/lookup';
import { useLazyGetMyAccountQuery } from 'services/myAccount/myAccount';
import { GetMyAccountResProps } from 'services/myAccount/myAccount.types';

import { selectLookup } from 'store';

import { defineFlowSteps } from 'containers/MigrateToTT/migrateToTT.settings';
import {
  ContentProps,
  MIGRATE_TO_TT_FLOW_STEPS,
  StepName
} from 'containers/MigrateToTT/migrateToTT.types';
import { notifyError } from 'shared/Toast/Toast';

import { useAppSelector, useQuery } from 'hooks';
import { PathName } from 'utils/enums';

export const useMigrateToTT = () => {
  const { membershipPlans } = useAppSelector(selectLookup);
  const [getMyAccount] = useLazyGetMyAccountQuery();
  useGetMembershipPlansQuery(undefined, { skip: membershipPlans.length > 0 });
  const [getMetrics] = useLazyGetLatestHealthMetricsQuery();

  const [loading, setLoading] = useState(true);
  const [isFetching, setIsFetching] = useState(false);
  const [steps, setSteps] = useState([...MIGRATE_TO_TT_FLOW_STEPS]);
  const query = useQuery();
  const navigate = useNavigate();
  const currentStep = (query.get('s') ?? '') as StepName;

  const fetchData = async (): Promise<
    | {
        error: string;
        heightAndWeight: null;
        myAccountData: null;
      }
    | {
        error: null;
        heightAndWeight: {
          height: number;
          weight: number;
        };
        myAccountData: GetMyAccountResProps;
      }
  > => {
    let myAccountData = null;
    let error = null;

    try {
      setIsFetching(true);
      myAccountData = await getMyAccount().unwrap();
      const metrics = await getMetrics().unwrap();
      return {
        error: null,
        myAccountData,
        heightAndWeight: {
          height: metrics?.data.height ?? 0,
          weight: metrics.data.weight ?? 0
        }
      };
    } catch (e) {
      error = (e as Error).message ?? 'Error fetching data';
      return { error, myAccountData: null, heightAndWeight: null };
    } finally {
      setIsFetching(false);
    }
  };

  const exitFlow = async () => {
    try {
      await fetchData();
    } finally {
      navigate(PathName.Dashboard);
    }
  };

  const moveToStep: ContentProps['moveToStep'] = (type, extraSearch = ''): void => {
    if (typeof type === 'object') {
    } else {
      const additionalSearch = extraSearch ? '&' + extraSearch : '';
      if (type === 'prev') {
        return navigate(-1);
      } else if (type === 'next') {
        const currentStepIndex = steps.indexOf(currentStep);
        let nextStep = steps[currentStepIndex + 1];

        if (currentStepIndex + 1 >= steps.length || !steps.includes(nextStep)) {
          exitFlow();
          return;
        }
        return navigate({ search: `s=${nextStep}${additionalSearch}` });
      } else {
        if (!steps.includes(type)) {
          notifyError('Something went wrong, please try again');
          return navigate({ search: `s=${steps[0]}` }, { replace: true });
        }
        navigate({ search: `s=${type}${additionalSearch}` });
      }
    }
  };

  const onInit = () => {
    const getData = async () => {
      try {
        setLoading(true);
        const { error } = await fetchData();
        if (error !== null) {
          return notifyError(error);
        }
        const filteredSteps = defineFlowSteps(MIGRATE_TO_TT_FLOW_STEPS);
        setSteps(filteredSteps);
        if (!currentStep || !filteredSteps.includes(currentStep)) {
          navigate({ search: `s=${filteredSteps[0]}` }, { replace: true });
        }
      } catch (e) {
        notifyError((e as Error).message ?? 'Error fetching data');
      } finally {
        setLoading(false);
      }
    };
    getData();
  };

  useEffect(onInit, []);

  return {
    currentStep,
    isFetching,
    loading: loading,
    moveToStep,
    steps
  };
};
