import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { selectShop } from 'store';
import { setShippingAddress } from 'store/shop/shopSlice';

import Address from 'shared/form/Address';

import { useAppDispatch, useAppSelector } from 'hooks';

import { MailingAddress } from 'models/user.types';

const ShippingAddress = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { shippingAddress } = useAppSelector(selectShop);
  const {
    handleSubmit,
    control,
    setValue,
    reset,
    formState: { isValid },
    trigger
  } = useForm<MailingAddress>({
    shouldFocusError: true,
    criteriaMode: 'all',
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const onSubmit = (data: MailingAddress) => {
    if (isValid) {
      dispatch(setShippingAddress(data));
      navigate(-1);
    } else {
      trigger();
    }
  };

  useEffect(() => {
    if (!!shippingAddress.address) {
      reset(shippingAddress);
    }
  }, []);

  return (
    <form className="flex h-full flex-col gap-10" onSubmit={handleSubmit(onSubmit)}>
      <Address control={control} setValue={setValue} trigger={trigger} />
      <div className="flex gap-4 max-md:mt-auto md:self-end">
        <Common.Button
          className="max-md:hidden"
          color="blue-alt"
          size="lg"
          type="button"
          fullWidthOnMobile
          onClick={() => navigate(-1)}
        >
          Back
        </Common.Button>
        <Common.Button color="blue" size="lg" type="submit" fullWidthOnMobile>
          Save
        </Common.Button>
      </div>
    </form>
  );
};

export default ShippingAddress;
