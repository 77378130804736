import { Common } from '@thecvlb/design-system/lib/src';
import classNames from 'classnames';

import { getOffset } from './bmiLine.settings';

interface BMIlineProps {
  value: number;
}

const BMILine: React.FC<BMIlineProps> = ({ value }) => {
  const tooltipClassName = classNames(
    'box--bottom absolute flex items-center gap-1 min-w-fit bg-black max-md:text-sm top-0 rounded-xl text-white max-w-[200px] shadow-sm p-3',
    getOffset(value)
  );

  return (
    <div className="relative flex min-w-full pt-12">
      <div className={tooltipClassName} data-testid="bmi-tooltip">
        <Common.Icon className="fill-white text-green" name="check-circle" />
        <span>Your BMI</span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full rounded-s-lg bg-red"></div>
        <span className="block font-bold">Below 27</span>
        <span className="px-0.5">Not qualified</span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-yellow"></div>
        <span className="block font-bold">27-29.9</span>
        <span className="px-0.5 text-center">
          May qualify;
          <br /> lab work required
        </span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-green-300"></div>
        <span className="block font-bold">30-34.9</span>
        <span className="px-0.5 text-center">May qualify</span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full bg-green-400"></div>
        <span className="block font-bold">35-39.9</span>
        <span className="px-0.5 text-center">May qualify</span>
      </div>
      <div className="flex w-1/5 flex-col items-center gap-1 text-mXs md:text-sm">
        <div className="h-4 w-full rounded-e-lg bg-green"></div>
        <span className="block font-bold">{'>40'}</span>
        <span className="px-0.5 text-center">May qualify</span>
      </div>
    </div>
  );
};

export default BMILine;
