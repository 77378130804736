import { CheckoutItem } from 'services/shop/shop.types';

interface SummaryCardProps {
  items: CheckoutItem[];
  total: number;
}

const SummaryCard: React.FC<SummaryCardProps> = ({ items, total }) => {
  return (
    <div className="flex flex-col gap-4 rounded-2xl bg-secondary-50 p-6">
      {items.map((item, index) => (
        <div className="flex flex-col gap-1" key={index}>
          <div className="flex grow items-center justify-between text-mBase font-bold text-primary-700">
            <span className="block max-w-[156px]">{item.product.name}</span>
            <span>${item.product.unitPrice}</span>
          </div>
          {item.product.type && (
            <span className="block text-mSm text-primary-700 md:text-sm">{item.product.type}</span>
          )}
        </div>
      ))}
      <div className="h-px w-full bg-secondary"></div>
      <div className="flex items-center justify-between text-mLg font-bold text-primary-700">
        <span>Total</span>
        <span>${total}</span>
      </div>
    </div>
  );
};

export default SummaryCard;
