import { useState } from 'react';
import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system';
import { AnimatePresence, motion } from 'framer-motion';

import PaymentMethodCard from 'features/PaymentMethods/PaymentMethodCard';
import PaymentMethodActions from 'modals/PaymentMethodActions';

import { useRouteMatch } from 'hooks/useRouteMatch';
import { PathName } from 'utils/enums';

import { Option } from 'models/forms.types';
import { PaymentMethodPurpose } from 'models/payments.types';

import { PaymentMethodsProps } from './paymentMethods.types';

const VARIANTS = (i: number) => ({
  exit: {
    height: 0,
    opacity: 0
  },
  hidden: {
    opacity: 0,
    rotateZ: -5,
    transformY: 50
  },
  visible: {
    opacity: 1,
    rotateZ: 0,
    transformY: 0,
    transition: {
      delay: i * 0.1 + 0.2,
      duration: 0.5
    }
  }
});

const tabs: Option<PaymentMethodPurpose>[] = [
  { label: 'Appointments', value: 'appointments' },
  { label: 'Monthly plan', value: 'membership' }
];

const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  creditBalance,
  paymentMethods,
  onUpdateFavoritePaymentMethod,
  isLoading,
  isInsuranceUser,
  onAddMethod
}) => {
  const isBillingDetails = useRouteMatch(PathName.BillingDetails)?.isExact;

  const [ppid, setPpid] = useState<string>('');
  const [activeTab, setActiveTab] = useState<PaymentMethodPurpose>('appointments');
  const [isExtended, setIsExtended] = useToggle(false);
  const [isOpen, toggleIsOpen] = useToggle(false);
  const filteredPaymentMethods = isInsuranceUser
    ? paymentMethods.filter((pm) => pm.type === activeTab)
    : paymentMethods;

  const currentPaymentMethod = filteredPaymentMethods.find((method) => method.isDefault);
  const otherPaymentMethods = filteredPaymentMethods.filter((method) => !method.isDefault);

  const handlePaymentMethod = (ppid: string = '') => {
    setPpid(ppid);
    toggleIsOpen();
  };

  const selectedPaymentMethod = filteredPaymentMethods.find((pm) => pm.paymentProfileId === ppid);

  return (
    <>
      <PaymentMethodActions
        activeTab={activeTab}
        isDefault={selectedPaymentMethod?.isDefault}
        isInsuranceUser={isInsuranceUser}
        isOpen={isOpen}
        isPayPalConnected={paymentMethods.some((pm) => pm.paymentMethod === 'paypal_account')}
        loading={isLoading}
        ppId={ppid}
        selectedPPType={selectedPaymentMethod?.type}
        onClose={(v) => {
          if (v && onAddMethod) {
            onAddMethod();
          }
          toggleIsOpen();
        }}
      />
      {Number(creditBalance) > 0 && (
        <div
          className="flex w-full items-center gap-6 rounded-2xl border border-gray-200 p-4"
          data-testid="credit_balance"
        >
          <div className="flex min-w-[60px] items-center self-stretch rounded-lg bg-secondary-50">
            <Common.Logo className="mx-auto w-4" name="cross" />
          </div>
          <div>
            <h4 className="mb-1 text-base font-bold">LifeMD credit: ${creditBalance}</h4>
            <h5 className="text-base font-medium text-gray">
              Your credit balance will be spent prior to charging your card.
            </h5>
          </div>
        </div>
      )}
      <AnimatePresence mode="wait">
        {!isExtended && currentPaymentMethod ? (
          <PaymentMethodCard
            data={currentPaymentMethod}
            isLoading={isLoading}
            key={currentPaymentMethod.paymentProfileId}
            setIsExtended={setIsExtended}
          />
        ) : (
          <>
            {isInsuranceUser && isBillingDetails && (
              <Common.Tabs
                data={tabs}
                type="pill"
                onChange={(v) => setActiveTab(v.value as PaymentMethodPurpose)}
              />
            )}

            <motion.div
              animate="open"
              className="flex flex-col gap-4 rounded-2xl border border-gray-200 p-4"
              exit="collapsed"
              initial="collapsed"
              key="collapsed"
              transition={{ duration: 0.3, ease: 'linear' }}
              variants={{
                collapsed: { height: 0, opacity: 0 },
                open: { height: 'auto', opacity: 1 }
              }}
            >
              <h3 className="font-bold text-primary-700" data-testid="current_payment">
                Current payment method
              </h3>
              {currentPaymentMethod && (
                <PaymentMethodCard
                  data={currentPaymentMethod}
                  isLoading={isLoading}
                  key={currentPaymentMethod.paymentProfileId}
                  onEdit={handlePaymentMethod}
                />
              )}
              {otherPaymentMethods.length > 0 && (
                <>
                  <h3 className="font-bold text-primary-700" data-testid="other_payment">
                    Other payment methods
                  </h3>
                  <AnimatePresence>
                    {otherPaymentMethods.map((p, i) => (
                      <motion.div
                        animate="visible"
                        exit="exit"
                        initial="hidden"
                        key={p.paymentProfileId}
                        variants={VARIANTS(i)}
                      >
                        <PaymentMethodCard
                          data={p}
                          isLoading={isLoading && ppid === p.paymentProfileId}
                          onEdit={handlePaymentMethod}
                          onPickAsFavorite={(id) => {
                            setPpid(id);
                            onUpdateFavoritePaymentMethod(
                              id,
                              () => {
                                isInsuranceUser && setActiveTab('appointments');
                                setIsExtended(false);
                              },
                              isInsuranceUser ? activeTab : undefined
                            );
                          }}
                        />
                      </motion.div>
                    ))}
                  </AnimatePresence>
                </>
              )}
              <button
                className="flex w-full justify-center gap-2 rounded-2xl border border-dashed border-gray-200 p-4 transition-all hover:bg-primary-100"
                data-testid="add_payment_method"
                type="button"
                onClick={() => handlePaymentMethod('new')}
              >
                <Common.Icon name="plus" />
                <div>Add payment method</div>
              </button>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default PaymentMethods;
