import { useToggle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useGetShopOrdersQuery, useLazyGetSingleOrderDetailsQuery } from 'services/shop/shop';

import ShopOrderDetails from 'modals/ShopOrderDetails';
import Loader from 'shared/Loader';

import { LINK_TO_SUPPORT } from 'constants/externalLinks';
import { handleRequestCatch } from 'utils/helpers';

import { OrderItem } from './components';

import EmptyOrders from 'assets/icons/search-with-cloud.svg?react';

const OrdersHistory = () => {
  const { data, isFetching } = useGetShopOrdersQuery();
  const [getOrderDetails, { data: singleOrderData, isFetching: isGettingOrderDetails }] =
    useLazyGetSingleOrderDetailsQuery();

  const [isOpenDetailsPopup, toggleDetailsPopup] = useToggle(false);

  const ordersList = data?.data ?? [];
  const isOrdersEmpty = ordersList.length === 0;

  const handleClickOnOrder = (orderId: string) => {
    toggleDetailsPopup();
    getOrderDetails(orderId).unwrap().catch(handleRequestCatch);
  };

  return (
    <>
      <ShopOrderDetails
        data={singleOrderData?.data}
        isOpen={isOpenDetailsPopup}
        loading={isGettingOrderDetails}
        onClose={toggleDetailsPopup}
      />
      <div className="items-between flex h-full flex-col">
        <Loader isVisible={isFetching} />
        <div className="h-full">
          {isOrdersEmpty && !isFetching ? (
            <div className="mx-auto flex h-full max-w-[306px] flex-col place-content-center items-center gap-2.5 divide-y-4 divide-gray-100 py-8 text-center max-md:grow">
              <EmptyOrders className="size-[52px]" />
              <span className="text-gray">
                No orders made yet. When you make a purchase your orders will appear here.
              </span>
            </div>
          ) : (
            ordersList.map((order) => (
              <button className="p-4" key={order._id} onClick={() => handleClickOnOrder(order._id)}>
                <OrderItem order={order} />
              </button>
            ))
          )}
        </div>
        <Common.Button
          className="mt-auto md:hidden"
          color="blue"
          fullWidthOnMobile
          onClick={() => window.open(LINK_TO_SUPPORT)}
        >
          Contact Support
        </Common.Button>
      </div>
    </>
  );
};

export default OrdersHistory;
