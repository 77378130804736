import { useNavigate } from 'react-router-dom';

import { useGetProductsQuery } from 'services/shop/shop';

import { selectMigrateToBundlePlanStatus } from 'store';

import ProductsList from 'features/store/ProductsList';
import Loader from 'shared/Loader';

import { useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';

import { FeaturedProduct, Tracker } from './components';

const Home = () => {
  const navigate = useNavigate();
  const isDisabledMigrateToBundlePlan = useAppSelector(selectMigrateToBundlePlanStatus);
  const { data: products, isFetching } = useGetProductsQuery();

  return (
    <div className="flex h-full flex-col gap-2.5">
      <Loader isVisible={isFetching} />
      {/* add when we'll have order */}
      {false && (
        <div className="md:max-w-[50%]">
          <Tracker order={{ id: '123', trackingNumber: '123123' }} />
        </div>
      )}
      {!isDisabledMigrateToBundlePlan && (
        <>
          <h3 className="pt-3 text-mBase font-semibold">Featured</h3>
          <div className="shadow-lg md:mb-6">
            <FeaturedProduct onUpgradePlan={() => navigate(PathName.MigrateToBundlePlan)} />
          </div>
        </>
      )}
      <h3 className="pt-3 text-mBase font-semibold">Devices</h3>
      <ProductsList products={products?.data || []} />
    </div>
  );
};

export default Home;
