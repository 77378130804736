import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import {
  useGetCreditCardInformationQuery,
  useUpdateDefaultPaymentMethodMutation
} from 'services/myAccount/myAccount';
import { useStoreCheckoutMutation } from 'services/shop/shop';

import { selectShop, selectUser } from 'store';
import { resetStore, setShippingAddress } from 'store/shop/shopSlice';

import PaymentMethods from 'features/PaymentMethods';
import EncryptedBadge from 'shared/EncryptedBadge';
import Loader from 'shared/Loader';
import { notifySuccess } from 'shared/Toast/Toast';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

import { PaymentMethod } from 'models/payments.types';

import { ShippingTo, SummaryCard } from './components';

const Checkout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { shippingAddress } = useAppSelector(selectShop);
  const { address, state, city, zipCode } = useAppSelector(selectUser);

  const { data, isFetching, isLoading, refetch } = useGetCreditCardInformationQuery();
  const [updateDefaultPaymentMethod, { isLoading: isUpdatingDefaultPaymentMethod }] =
    useUpdateDefaultPaymentMethodMutation();

  const [checkout, { data: checkoutData, isLoading: isLoadingCheckout }] =
    useStoreCheckoutMutation();
  const productsCheckoutInfo = checkoutData?.data;

  const handleFavoritePaymentMethod = async (id: string, cb?: () => void) => {
    if (!id) return;
    try {
      const response = await updateDefaultPaymentMethod({
        id
      }).unwrap();
      await refetch().unwrap();
      notifySuccess(response.message ?? 'Updated default payment method');
      cb?.();
    } catch (e) {
      handleRequestCatch(e as MessageEvent);
    }
  };

  const getPaymentMethods = (): PaymentMethod[] => {
    return (
      data?.data?.map((paymentMethod) => ({
        ...paymentMethod,
        type: 'membership'
      })) ?? []
    );
  };

  const handleSubmit = () => {
    checkout({
      processPayment: true,
      shippingAddress
    })
      .unwrap()
      .then((res) => {
        if (res.data) {
          navigate(PathName.ShopOrders + '/' + res.data.orderId);
          dispatch(resetStore());
        }
      })
      .catch(handleRequestCatch);
  };

  const headingClassName = 'font-bold md:text-xl';
  const addressToShow = `${shippingAddress.address} ${shippingAddress.city}, ${shippingAddress.state} ${shippingAddress.zipCode}`;

  useEffect(() => {
    if (!shippingAddress.address) {
      dispatch(
        setShippingAddress({
          address,
          city,
          state,
          zipCode
        })
      );
    }
  }, []);

  useEffect(() => {
    !!shippingAddress.address &&
      checkout({
        processPayment: false,
        shippingAddress
      });
  }, [shippingAddress]);

  return (
    !!productsCheckoutInfo && (
      <div className="flex h-full flex-col gap-4 md:mx-auto md:max-w-[420px] md:gap-8">
        <Loader isVisible={isLoadingCheckout} />
        <h2 className="max-md:hidden">Confirm and pay</h2>
        <div className="flex flex-col gap-4">
          <h2 className={headingClassName}>Ship to</h2>
          <ShippingTo address={addressToShow} onClickEdit={() => navigate(PathName.Shop)} />
        </div>
        <div className="flex flex-col gap-4">
          <h2 className={headingClassName}>Payment</h2>
          <div className="flex flex-col gap-4">
            {!isLoading && (
              <PaymentMethods
                isLoading={isFetching}
                paymentMethods={getPaymentMethods()}
                onAddMethod={refetch}
                onUpdateFavoritePaymentMethod={handleFavoritePaymentMethod}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h2 className={headingClassName}>Items</h2>
          <SummaryCard items={productsCheckoutInfo.items} total={productsCheckoutInfo.total} />
        </div>
        <div className="flex flex-col gap-4 max-md:mt-auto max-md:py-6">
          <Common.Button
            className="!w-full"
            color="blue"
            disabled={isUpdatingDefaultPaymentMethod || isLoadingCheckout}
            fullWidthOnMobile
            onClick={handleSubmit}
          >
            Confirm ${productsCheckoutInfo.total} payment
          </Common.Button>
          <EncryptedBadge />
        </div>
      </div>
    )
  );
};

export default Checkout;
