import classNames from 'classnames';

import { Props } from './updatePlanCard.types';

const UpdatePlanCard: React.FC<Props> = ({
  plan,
  previewChangeSubscription,
  creditBalance,
  oldPlan,
  additionalPurchaseInfo
}) => {
  const oldMonths = oldPlan?.pricePoint.paymentInterval.qty;
  const months = plan?.pricePoint.paymentInterval.qty;

  const rowClassName = 'mb-1 flex justify-between md:text-lg font-bold md:mb-0.5';
  const rowWrapperClassName = 'mb-4 md:mb-3';
  const subheadingClassName = 'block text-mSm md:text-sm';

  const discount = previewChangeSubscription?.newDiscount || previewChangeSubscription?.discount;

  return (
    <div className="rounded-2xl border border-secondary-200 bg-secondary-50 px-4 py-6 md:p-6">
      <div>
        {oldPlan && oldMonths && (
          <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
            <div className={rowClassName}>
              <span className="block">Old plan</span>
              <span className="line-through" data-testid="old_monthly_price">{`$${
                oldPlan.pricePoint.totalCost
              }/${oldMonths > 1 ? `every ${oldMonths}` : ''} mo`}</span>
            </div>
            <span className={subheadingClassName}>{oldPlan.planName}</span>
          </div>
        )}
        <div className={classNames(rowWrapperClassName, 'text-secondary')}>
          <div className={rowClassName}>
            <span className="block">New plan</span>
            <span data-testid="new_monthly_price">{`$${
              plan.pricePoint.totalCost
            }/${months > 1 ? `every ${months}` : ''} mo`}</span>
          </div>
          <span className={subheadingClassName}>{plan.planName}</span>
        </div>
        {!!additionalPurchaseInfo && (
          <div className={classNames('mb-4 md:mb-4', additionalPurchaseInfo.color)}>
            <div className="flex justify-between font-bold md:text-lg">
              <h3>{additionalPurchaseInfo.header}</h3>
              <span>${additionalPurchaseInfo.price}</span>
            </div>
            {!!additionalPurchaseInfo.subheader && (
              <span className="block text-left text-sm">{additionalPurchaseInfo.subheader}</span>
            )}
          </div>
        )}
        {!!discount && (
          <div className={classNames(rowWrapperClassName, 'text-orange')}>
            <div className={rowClassName}>
              <span>Discount</span>
              <span>-${discount.discountAmount}</span>
            </div>
            <span className={subheadingClassName}>{discount.title}</span>
          </div>
        )}
        <div className="my-4 h-px w-full bg-secondary" />
        <div className={classNames(rowWrapperClassName, 'text-primary-700')}>
          <div className={rowClassName}>
            <span>
              {previewChangeSubscription.migrationHasPayment ? 'Total due now' : 'Credit applied'}
            </span>
            <span className={previewChangeSubscription.migrationHasPayment ? '' : 'text-green'}>
              {previewChangeSubscription.migrationHasPayment
                ? `$${previewChangeSubscription.paymentDue}`
                : `+$${previewChangeSubscription.creditApplied}`}
            </span>
          </div>
        </div>
      </div>
      <p className="text-mSm md:text-sm">
        {`Your new membership begins immediately and will renew again in ${months} months. We’ll prorate a refund of $${previewChangeSubscription.proratedAdjustment} for the ${previewChangeSubscription.billingPeriodDaysRemaining} days remaining in your previous billing cycle, and charge a full amount of $${previewChangeSubscription.charge} for your new plan. `}
        {previewChangeSubscription.migrationHasPayment
          ? `This will result in a total of $${previewChangeSubscription.paymentDue} due.`
          : `This will result in a total of $${previewChangeSubscription.creditApplied} credited to your account.`}
        {Number(creditBalance) > 0 && (
          <>
            <br />
            <br />
            Your <b>LifeMD credit balance of ${creditBalance}</b> will be billed first, followed by
            your card on file.
          </>
        )}
      </p>
    </div>
  );
};

export default UpdatePlanCard;
