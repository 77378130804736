import classNames from 'classnames';

import ProductCard from 'features/store/ProductCard';

import { ProductsList as ProductsListType } from 'models/shop';
interface ProductsListProps {
  products: ProductsListType;
}

const ProductsList: React.FC<ProductsListProps> = ({ products }) => {
  return (
    <div
      className={classNames('grid w-full gap-3 md:gap-4', {
        'grid-cols-2': !(products.length % 2)
      })}
    >
      {products.map((item) => (
        <ProductCard info={item} key={item._id} />
      ))}
    </div>
  );
};

export default ProductsList;
