import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import { selectOrchestrate } from 'store';

import { MixedSignupStepProps } from 'containers/SignUp/Content/content.types';
import FadeWrapper from 'shared/animationWrappers/FadeWrapper';
import RadioGroup from 'shared/RadioGroup';

import { useAppSelector, useQuery } from 'hooks';
import useSubmitOrchestrateForm from 'hooks/useSubmitOrchestrateForm';
import { buildSearch } from 'utils/helpers';

import Advantage from '../parts/Advantage';
import BackToMainWebsiteButton from '../parts/BackToMainWebsiteButton';
import Heading from '../parts/Heading';

const ITEMS = [
  {
    label: (
      <>
        <p className="font-bold">Employer / Commercial or Exchange (ACA) Plan</p>
        <p className="text-mSm text-gray md:text-sm">IE: Aetna, Humana, etc</p>
      </>
    ),
    value: 'employer_commercial'
  },
  {
    label: (
      <>
        <p className="font-bold">Medicare or Medicaid</p>
        <p className="text-mSm text-gray md:text-sm">
          Government plans not including VA or TRICARE
        </p>
      </>
    ),
    value: 'government'
  },
  {
    label: (
      <>
        <p className="font-bold">Veterans Affairs (VA) / TRICARE</p>
        <p className="text-mSm text-gray md:text-sm">
          IE: Humana Military, Health Net Federal Services, etc
        </p>
      </>
    ),
    value: 'veterans_tricare'
  },
  {
    label: (
      <>
        <p className="font-bold">Uninsured</p>
        <p className="text-mSm text-gray md:text-sm">I do not have Medical Insurance</p>
      </>
    ),
    value: 'uninsured'
  }
];

const InsuranceType: React.FC<{
  onCancel: () => void;
  onComplete: (v: string) => void;
  selectedFlow: MixedSignupStepProps['selectedFlow'];
}> = ({ onComplete, onCancel, selectedFlow }) => {
  const prefix = selectedFlow === 'authorized' ? 's=wm-insurance' : '';

  const [activeItem, setActiveItem] = useState('');
  const query = useQuery();
  const timer = useRef<ReturnType<typeof setTimeout>>();
  const { send, isLoading } = useSubmitOrchestrateForm();
  const {
    user: { email }
  } = useAppSelector(selectOrchestrate);

  const navigate = useNavigate();
  const [step, setStep] = useState<'initial' | 'alt'>('initial');

  const handleSelect = (v: string) => {
    if (v === 'government') {
      navigate({
        search: buildSearch(prefix, `hideProgress=true&subStep=fail-insurance&answer=${v}`)
      });
      return;
    }
    setActiveItem(v);
    send('mif_qa', [{ answer: v, question: 'insurance_details' }], () => {
      setActiveItem('');
      navigate({ search: buildSearch(prefix, 'hideProgress=true') }, { replace: true });
      setStep('alt');
      timer.current = setTimeout(() => {
        navigate({ search: buildSearch(prefix) }, { replace: true });
        onComplete(v);
      }, 2700);
    });
  };

  const handleProspect = () => {
    const answer = query.get('answer');
    if (answer && !!email) {
      send(
        'mif_qa',
        [{ answer, question: 'insurance_details' }],
        () => {
          onCancel();
        },
        'PROSPECT'
      );
    } else {
      onCancel();
    }
  };

  const isErrorState = query.get('subStep') === 'fail-insurance';

  useEffect(() => {
    navigate({ search: buildSearch(prefix) }, { replace: true });
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  return (
    <AnimatePresence mode="wait">
      {isErrorState ? (
        <FadeWrapper className="flex h-full flex-col gap-6 text-primary-700" key="forbidden">
          <h2 className="wm-signup-title" data-testid="header">
            Sorry, we do not currently accept Medicare or Medicaid at this time for GLP-1 treatment.
          </h2>
          <p>
            At this time, those with any form of government healthcare coverage (including programs
            such as Medicare, Medicaid or TRICARE), whether primary or secondary or
            government-related coverage such as Medicare Supplement Plans, are not eligible for
            LifeMD GLP-1 weight loss programs.
          </p>
          <p>
            These plans typically do not cover the type of medication LifeMD programs may prescribe.
            Unfortunately, this means that those on Medicare or eligible for Medicare cannot join
            the LifeMD GLP-1 weight loss programs.
          </p>
          <BackToMainWebsiteButton loading={isLoading} onClick={handleProspect} />
        </FadeWrapper>
      ) : step === 'initial' ? (
        <FadeWrapper className="flex flex-col gap-6" key="initial">
          <Heading
            category="Plan"
            subtitle="Although GLP-1 medication is cash-pay for most patients, those with certain health conditions and/or insurance plans may be eligible for insurance coverage on their medication."
            title="Which health insurance do you have?"
          />
          <RadioGroup
            isLoading={isLoading}
            items={ITEMS}
            selectedItem={activeItem}
            onSelect={handleSelect}
          />
        </FadeWrapper>
      ) : (
        <FadeWrapper className="flex h-full flex-col place-items-center p-6" key="alt">
          <Advantage selectedFlow={selectedFlow} />
        </FadeWrapper>
      )}
    </AnimatePresence>
  );
};

export default InsuranceType;
