import { useTitle } from 'react-use';

import Loader from 'shared/Loader';
import Header from 'widgets/Header';

import { useMigrateToTT } from 'hooks/useMigrateToTT';

import Content from './Content';

const MigrateToTT = () => {
  useTitle('LifeMD - Migrate to Triple Therapy');
  const { steps, currentStep, moveToStep, loading, isFetching } = useMigrateToTT();

  return loading ? (
    <Loader isVisible />
  ) : (
    <div className="relative flex grow flex-col p-4">
      <Header
        flowLength={steps.length}
        step={steps.indexOf(currentStep)}
        onClickPrev={() => moveToStep('prev')}
      />
      <div className="grow">
        <Content loading={isFetching} moveToStep={moveToStep} step={currentStep} steps={steps} />
      </div>
    </div>
  );
};

export default MigrateToTT;
