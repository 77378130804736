import { useEffect, useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate, useParams } from 'react-router-dom';
import { useTitle } from 'react-use';
import { Common } from '@thecvlb/design-system/lib/src';

import { useAddToCartMutation, useGetSingleProductDetailsQuery } from 'services/shop/shop';

import { selectShop } from 'store';
import { setActiveProductName } from 'store/shop/shopSlice';

import AccordionInfo from 'features/AccordionInfo/AccordionInfo';
import Loader from 'shared/Loader';

import { useAppDispatch, useAppSelector } from 'hooks';
import { PathName } from 'utils/enums';
import { handleRequestCatch } from 'utils/helpers';

const Product = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id = '' } = useParams<{ id: string }>();
  const { cart } = useAppSelector(selectShop);

  const { data, isFetching } = useGetSingleProductDetailsQuery(id);
  const [activeIndex, setActiveIndex] = useState(0);
  const productInfo = data?.data;

  const [addToCart, { isLoading }] = useAddToCartMutation();
  useTitle(`Store - ${productInfo?.name || 'product details'}`);

  const isItemIncludedToCart = cart.items.some((item) => item.productId === id);

  const handleAddToCart = () => {
    if (isItemIncludedToCart) {
      return navigate(PathName.ShopCart);
    }
    addToCart({ productId: id, count: 1 })
      .unwrap()
      .then(() => {
        navigate(PathName.ShopCart);
      })
      .catch(handleRequestCatch);
  };

  const getThumbs = () =>
    !!productInfo
      ? productInfo.assets.map((asset) => (
          <img
            alt={asset.url}
            className="size-10 max-h-[40px] select-none object-contain"
            key={asset.url}
            src={asset.url}
          />
        ))
      : [];

  useEffect(() => {
    dispatch(setActiveProductName(productInfo?.name ?? ''));
    return () => {
      dispatch(setActiveProductName(''));
    };
  }, [productInfo]);

  const isPriceWithDiscount =
    Number(productInfo?.originPrice?.unitPrice) > Number(productInfo?.price.unitPrice);

  return (
    <>
      <Loader isVisible={isFetching} />
      {!!productInfo && (
        <div className="mt-5 grid gap-5 md:grid-cols-2 md:gap-2.5">
          <div className="w-full max-w-full overflow-hidden md:h-[440px]">
            <Carousel
              centerSlidePercentage={100}
              renderThumbs={getThumbs}
              selectedItem={activeIndex}
              showArrows={false}
              showIndicators={false}
              showStatus={false}
              swipeScrollTolerance={20}
              thumbWidth={50}
              centerMode
              emulateTouch
              infiniteLoop
              preventMovementUntilSwipeScrollTolerance
              swipeable
              onChange={setActiveIndex}
            >
              {productInfo.assets.map((image) => (
                <div className="select-none rounded-2xl bg-gray-100 p-10 md:mx-2">
                  <img
                    alt="img"
                    className="max-h-[300px] object-contain"
                    key={image.url}
                    src={image.url}
                  />
                </div>
              ))}
            </Carousel>
          </div>
          <div>
            <div className="mb-3 flex items-center justify-between">
              <div className="text-mLg">
                <h3 className="font-semibold text-gray-800 md:font-bold">{productInfo.name}</h3>
                <span className="text-mBase md:text-lg">{productInfo.type}</span>
              </div>
              <div>
                <p className="text-mLg">
                  {isPriceWithDiscount ? (
                    <span>
                      <span className="text-green">
                        <span className="mr-1 text-gray line-through">
                          ${productInfo.originPrice?.unitPrice}
                        </span>
                        ${productInfo.price.unitPrice}
                      </span>
                    </span>
                  ) : (
                    <span className="text-gray-700">${productInfo.price.unitPrice}</span>
                  )}
                </p>
              </div>
            </div>
            <p>{productInfo.description}</p>
            <div>
              <div className="mb-4">
                <AccordionInfo items={productInfo.extraDescription} />
              </div>
              <Common.Button
                className="!w-full max-md:hidden"
                color="blue"
                isLoading={isLoading}
                fullWidthOnMobile
                onClick={handleAddToCart}
              >
                {isItemIncludedToCart ? 'View in cart' : 'Add to cart'}
              </Common.Button>
            </div>
          </div>
          <Common.Button
            className="z-20 max-md:sticky max-md:bottom-4 md:hidden"
            color="blue"
            isLoading={isLoading}
            size="lg"
            fullWidthOnMobile
            onClick={handleAddToCart}
          >
            {isItemIncludedToCart ? 'View in cart' : 'Add to cart'}
          </Common.Button>
        </div>
      )}
    </>
  );
};

export default Product;
