import { Common } from '@thecvlb/design-system/lib/src';

import { CartItem as CartItemType } from 'models/shop';
interface CartItemProps {
  info: CartItemType['product'];
  onDelete: () => void;
}

const CartItem: React.FC<CartItemProps> = ({
  info: { featuredAsset, name, type, unitPrice },
  onDelete
}) => {
  return (
    <div className="flex items-center justify-between gap-3 py-2">
      <div className="flex-none rounded-lg bg-gray-100 p-4">
        <img alt="product" className="size-[150px] object-cover" src={featuredAsset.url} />
      </div>
      <div className="flex grow flex-col justify-between self-stretch">
        <div className="flex items-start justify-between">
          <div>
            <h3 className="font-semibold text-gray-800">{name}</h3>
            <span className="text-mSm text-gray md:text-sm">{type}</span>
          </div>
          <span className="text-gray-800">${unitPrice}</span>
        </div>
        <button
          className="self-end"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <Common.Icon name="trash" />
        </button>
      </div>
    </div>
  );
};

export default CartItem;
