import { useNavigate } from 'react-router-dom';
import { Common } from '@thecvlb/design-system/lib/src';

import { useDeleteFromCartMutation, useGetCartQuery } from 'services/shop/shop';

import Loader from 'shared/Loader';

import { PathName } from 'utils/enums';

import { CartItem } from './components';

import EmptyCart from 'assets/icons/empty-cart.svg?react';

const Cart = () => {
  const navigate = useNavigate();
  const [deleteProduct, { isLoading }] = useDeleteFromCartMutation();
  const { data, refetch, isFetching } = useGetCartQuery();

  const handleDelete = (id: string) => {
    if (!!id) {
      deleteProduct({ productId: id }).unwrap().then(refetch);
    }
  };
  const cartProducts = data?.data.items ?? [];
  const isCartEmpty = cartProducts.length === 0;

  const handleContinueToCheckout = () => {
    navigate(isCartEmpty ? PathName.Shop : PathName.ShopCheckout);
  };

  return (
    <div className="flex min-h-full flex-col">
      <Loader isVisible={isFetching} />
      <div className="flex h-full flex-col place-content-center divide-y-4 divide-gray-100 max-md:grow">
        {isCartEmpty && !isFetching ? (
          <div className="mx-auto flex max-w-[306px] flex-col items-center gap-2.5 py-8 text-center">
            <EmptyCart className="size-[52px]" />
            <span className="text-gray">
              Your cart is empty. When you add products, they’ll appear here.
            </span>
          </div>
        ) : (
          cartProducts.map((product) => (
            <button onClick={() => navigate(PathName.ShopProducts + '/' + product.productId)}>
              <CartItem
                info={product.product}
                key={product.productId}
                onDelete={() => handleDelete(product.productId)}
              />
            </button>
          ))
        )}
      </div>
      {
        <div className="max-md:mt-auto md:w-full md:max-w-[360px] md:self-end">
          {!!data?.data.subTotal && (
            <div className="py-4">
              <div className="flex justify-between text-mLg font-bold text-primary">
                <span>Sub total</span>
                <span className="text-mSm text-gray">{data.data.subTotal}</span>
              </div>
              <span>Does not include shipping or taxes</span>
            </div>
          )}
          <Common.Button
            className="!w-full"
            color="blue"
            disabled={isLoading || isFetching}
            fullWidthOnMobile
            onClick={handleContinueToCheckout}
          >
            {isCartEmpty ? 'View Products' : 'Continue to checkout'}
          </Common.Button>
        </div>
      }
    </div>
  );
};

export default Cart;
