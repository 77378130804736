import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import {
  GenerateDynamicLinkReqProps,
  GenerateDynamicLinkResProps
} from 'services/general/general.types';
import { ResponseProps } from 'services/services.types';

import { setUserState } from 'store/signup/signupSlice';
import { clearUser, setToken } from 'store/user/userSlice';

import packageInfo from '../../../package.json';

import {
  AddChargifyTokenReqProps,
  AddChargifyTokenResProps,
  AddFirstAppointmentReqProps,
  AddFirstAppointmentResProps,
  AddPersonalInfoReqProps,
  AddPersonalInfoResProps,
  ChangePasswordReqProps,
  ChangePasswordResProps,
  ChangePasswordSendCodeReqProps,
  ChangePasswordSendCodeResProps,
  ChangePasswordVerifyCodeReqProps,
  ChangePasswordVerifyCodeResProps,
  CheckIfAsapOptionReqProps,
  CheckIfAsapOptionResProps,
  ConfirmSignInReqProps,
  ConfirmSignInResProps,
  ConfirmSignUpReqProps,
  ConfirmSignUpResProps,
  CreateAccountAfterPaymentReqProps,
  CreateAccountWithPaymentReqProps,
  CreateAccountWithPaymentResProps,
  CreateProspectUserReqProps,
  CreateProspectUserResProps,
  ForgotPasswordReqProps,
  ForgotPasswordResProps,
  ForgotPasswordTokenReqProps,
  ForgotPasswordTokenResProps,
  GetAvailableDatesReqProps,
  GetAvailableDatesResProps,
  GetCampaignInfoResProps,
  GetCombinedAvailabilityReqProps,
  GetCombinedAvailabilityResProps,
  GetProviderImagesReqProps,
  GetProviderImagesResProps,
  GetProvidersForStateReqProps,
  GetProvidersForStateResProps,
  GetProvidersReqProps,
  GetProvidersResProps,
  GetShortTermTokenResProps,
  HoldProviderReqProps,
  HoldProviderResProps,
  LoginReqProps,
  LoginResProps,
  LogoutReqProps,
  LogoutResProps,
  PatientLoginReqProps,
  PatientLoginResProps,
  PaymentCombinedReqProps,
  PaymentCombinedResProps,
  ReleaseSlotsReqProps,
  ReleaseSlotsResProps,
  SendOrchestrateFormReqProps,
  SendOrchestrateFormResProps,
  SendVerifyEmailReqProps,
  SendVerifyEmailResProps,
  SignInWithAppleReqProps,
  SignInWithAppleResProps,
  SignInWithGoogleReqProps,
  SignInWithGoogleResProps,
  SignUpReqProps,
  SignUpResProps,
  VerifyUserTokenReqProps,
  VerifyUserTokenResProps
} from './auth.types';

export const authApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE_URL,
    prepareHeaders: (headers, { endpoint }) => {
      const ENDPOINTS_TO_COLLECT_DATA = [
        'getProviders',
        'checkIfAsapOptionAvailable',
        'addChargifyToken',
        'addPersonalInfo',
        'holdProvider',
        'combinedPayment'
      ];
      headers.set('API-KEY', import.meta.env.VITE_API_KEY ?? '');
      headers.set('App-Version', packageInfo.version);
      headers.set('Platform', 'web-patient');
      const onboardingSession = sessionStorage.getItem('onboarding-id');
      if (!!onboardingSession && ENDPOINTS_TO_COLLECT_DATA.includes(endpoint)) {
        headers.set('onboarding-id', onboardingSession);
      }
      return headers;
    }
  }),
  endpoints: (build) => ({
    addChargifyToken: build.mutation<AddChargifyTokenResProps, AddChargifyTokenReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/create-account/payment'
      })
    }),
    addFirstAppointment: build.mutation<AddFirstAppointmentResProps, AddFirstAppointmentReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          'API-KEY': import.meta.env.VITE_API_KEY,
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/first-appointment'
      })
    }),
    addPersonalInfo: build.mutation<AddPersonalInfoResProps, AddPersonalInfoReqProps>({
      query: ({ accessToken, sessionId, ...body }) => ({
        body: {
          ...body,
          flowUrl: window.location.href,
          referrerUrl: window.document.referrer
        },
        headers: {
          Authorization: accessToken,
          'session-id': sessionId
        },
        method: 'POST',
        url: '/auth/create-account/personal-info'
      })
    }),
    // Change Password: Step 3 - Change Password
    // Note: requires same code as on Step 2.
    changePassword: build.mutation<ChangePasswordResProps, ChangePasswordReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'PATCH',
        url: '/auth/change-password/change-password'
      })
    }),
    // Change Password: Step 1 - Send Code
    changePasswordSendCode: build.mutation<
      ChangePasswordSendCodeResProps,
      ChangePasswordSendCodeReqProps
    >({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/change-password/send-code'
      })
    }),
    // Change Password: Step 2 - Verify Code
    changePasswordVerifyCode: build.mutation<
      ChangePasswordVerifyCodeResProps,
      ChangePasswordVerifyCodeReqProps
    >({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/change-password/verify-code'
      })
    }),
    checkIfAsapOptionAvailable: build.query<CheckIfAsapOptionResProps, CheckIfAsapOptionReqProps>({
      query: (params) => ({
        method: 'GET',
        params,
        url: '/auth/queue-availability'
      })
    }),
    combinedPayment: build.mutation<PaymentCombinedResProps, PaymentCombinedReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/subscription'
      })
    }),

    // Should only be called after previous IDP endpoint returned CONFIRM_SIGNIN.
    confirmSignIn: build.mutation<ConfirmSignInResProps, ConfirmSignInReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/idp/confirm-signin'
      })
    }),
    // Should only be called after previous IDP endpoint returned CONFIRM_SIGNUP
    confirmSignUp: build.mutation<ConfirmSignUpResProps, ConfirmSignUpReqProps>({
      query: (body) => ({
        body: {
          ...body,
          flowUrl: window.location.href,
          referrerUrl: window.document.referrer
        },
        method: 'POST',
        url: '/auth/idp/confirm-signup'
      })
    }),
    createAccountWithPayment: build.mutation<
      CreateAccountWithPaymentResProps,
      CreateAccountWithPaymentReqProps
    >({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/create-account/payment-new-flow'
      })
    }),
    createProspectUser: build.mutation<CreateProspectUserResProps, CreateProspectUserReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/create-prospect'
      })
    }),
    createUserDetailsAfterPayment: build.mutation<
      CreateAccountWithPaymentResProps,
      CreateAccountAfterPaymentReqProps
    >({
      query: ({ accessToken, sessionId, ...body }) => ({
        body: {
          ...body,
          flowUrl: window.location.href,
          referrerUrl: window.document.referrer
        },
        headers: {
          Authorization: accessToken,
          ...(sessionId && { 'session-id': sessionId })
        },
        method: 'POST',
        url: '/auth/create-account/personal-info-new-flow'
      })
    }),
    forgotPassword: build.mutation<ForgotPasswordResProps, ForgotPasswordReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/forgot-password'
      })
    }),
    forgotPasswordToken: build.mutation<ForgotPasswordTokenResProps, ForgotPasswordTokenReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/forgot-password-token'
      })
    }),
    generateDynamicLink: build.mutation<GenerateDynamicLinkResProps, GenerateDynamicLinkReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/generate-dynamic-link'
      })
    }),
    getAvailableDates: build.query<GetAvailableDatesResProps, GetAvailableDatesReqProps>({
      query: ({ accessToken, ...params }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        params,
        url: '/auth/available-dates'
      })
    }),
    getCampaignInfo: build.query<GetCampaignInfoResProps, string>({
      query: (id) => `/auth/campaigns?recId=${id}`
    }),
    getGuestCombinedAvailability: build.query<
      GetCombinedAvailabilityResProps,
      GetCombinedAvailabilityReqProps
    >({
      query: ({ sessionId, accessToken, ...params }) => ({
        headers: {
          'session-id': sessionId,
          ...(accessToken && { Authorization: accessToken })
        },
        params,
        url: '/auth/available-time-slots'
      })
    }),
    getProviderImages: build.query<GetProviderImagesResProps, GetProviderImagesReqProps>({
      query: ({ accessToken }) => ({
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'GET',
        url: '/auth/create-patient-account/default-profile-images'
      })
    }),
    getProviders: build.query<GetProvidersResProps, GetProvidersReqProps>({
      keepUnusedDataFor: 3,
      query: (params) => ({ params, url: `/auth/doctors` })
    }),
    getProvidersForState: build.query<GetProvidersForStateResProps, GetProvidersForStateReqProps>({
      keepUnusedDataFor: 3,
      query: (params) => ({
        params,
        url: '/auth/available-doctors'
      })
    }),
    getShortTermToken: build.mutation<GetShortTermTokenResProps, string>({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/short-lived-token'
      })
    }),
    holdProvider: build.mutation<HoldProviderResProps, HoldProviderReqProps>({
      query: ({ sessionId, ...body }) => ({
        body,
        headers: {
          'session-id': sessionId
        },
        method: 'POST',
        url: '/auth/hold-doctor'
      })
    }),
    login: build.mutation<LoginResProps, LoginReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/login'
      })
    }),
    logout: build.mutation<LogoutResProps, LogoutReqProps>({
      query: (accessToken) => ({
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/logout'
      })
    }),
    patientLogin: build.mutation<PatientLoginResProps, PatientLoginReqProps>({
      query: (params) => ({
        method: 'POST',
        params,
        url: '/auth/staff/patient-login'
      })
    }),
    releaseSlots: build.mutation<ReleaseSlotsResProps, ReleaseSlotsReqProps>({
      query: (sessionId) => ({
        headers: {
          'session-id': sessionId
        },
        method: 'POST',
        url: '/auth/release-slots'
      })
    }),
    /**
     * Send verify email manually.
     *  Used for:
     *  - Resend verify email.
     *  - Legacy user verify email.
     */
    resendVerifyEmail: build.mutation<SendVerifyEmailResProps, SendVerifyEmailReqProps>({
      query: ({ accessToken, ...body }) => ({
        body,
        headers: {
          Authorization: accessToken
        },
        method: 'POST',
        url: '/auth/verify-email/resend'
      })
    }),
    sendOrchestrateForm: build.mutation<SendOrchestrateFormResProps, SendOrchestrateFormReqProps>({
      query: ({ accessToken, ...body }) => ({
        body: { ...body, ...(accessToken && { existingUser: true }) },
        credentials: 'include',
        headers: {
          ...(accessToken && { Authorization: accessToken })
        },
        method: 'POST',
        url: '/auth/orchestrate-signup'
      })
    }),
    getOrchestrateSessionInfo: build.query<SendOrchestrateFormResProps, string>({
      query: (accessToken) => ({
        credentials: 'include',
        headers: {
          ...(!!accessToken && { Authorization: accessToken })
        },
        url: '/auth/orchestrate-signup'
      })
    }),
    signInWithApple: build.mutation<SignInWithAppleResProps, SignInWithAppleReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/idp/apple'
      })
    }),
    signInWithGoogle: build.mutation<SignInWithGoogleResProps, SignInWithGoogleReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/idp/google'
      })
    }),
    signUp: build.mutation<SignUpResProps, SignUpReqProps>({
      query: (body) => ({
        body: {
          ...body,
          flowUrl: window.location.href,
          referrerUrl: window.document.referrer
        },
        method: 'POST',
        url: '/auth/freemium/sign-up'
      })
    }),
    verifyUserToken: build.mutation<VerifyUserTokenResProps, VerifyUserTokenReqProps>({
      query: (body) => ({
        body,
        method: 'POST',
        url: '/auth/token-verify'
      })
    }),
    refreshToken: build.mutation<
      ResponseProps<{ accessToken: string; refreshToken: string }>,
      { isAuthorized: boolean; token: string }
    >({
      async onQueryStarted({ isAuthorized = true }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(isAuthorized ? setToken(data.data) : setUserState(data.data));
          }
        } catch (_) {
          dispatch(clearUser());
          throw Error('Error while getting refresh token');
        }
      },
      query: ({ token }) => ({
        headers: {
          Authorization: token
        },
        body: { refreshToken: token },
        method: 'POST',
        url: '/auth/token-renewal'
      })
    })
  }),
  reducerPath: 'authApi'
});

export const {
  useLazyGetGuestCombinedAvailabilityQuery,
  useLoginMutation,
  usePatientLoginMutation,
  useSignUpMutation,
  useCombinedPaymentMutation,
  useLogoutMutation,
  useForgotPasswordTokenMutation,
  useForgotPasswordMutation,
  useReleaseSlotsMutation,
  useGetProvidersQuery,
  useHoldProviderMutation,
  useAddFirstAppointmentMutation,
  useAddPersonalInfoMutation,
  useAddChargifyTokenMutation,
  useGetProvidersForStateQuery,
  useCreateAccountWithPaymentMutation,
  useCreateUserDetailsAfterPaymentMutation,
  useCreateProspectUserMutation,
  useLazyGetAvailableDatesQuery,
  useVerifyUserTokenMutation,
  useGetProviderImagesQuery,
  useLazyCheckIfAsapOptionAvailableQuery,
  useLazyGetCampaignInfoQuery,
  useGetShortTermTokenMutation,
  useSignInWithGoogleMutation,
  useConfirmSignInMutation,
  useConfirmSignUpMutation,
  useSignInWithAppleMutation,
  useGenerateDynamicLinkMutation,
  useResendVerifyEmailMutation,
  useChangePasswordMutation,
  useChangePasswordSendCodeMutation,
  useChangePasswordVerifyCodeMutation,
  useSendOrchestrateFormMutation,
  useLazyGetOrchestrateSessionInfoQuery,
  useRefreshTokenMutation
} = authApi;
